import React, { useEffect, useCallback } from "react";
import Dialog, { DialogProps } from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { AbstractConnector } from "@web3-react/abstract-connector";
import { useDispatch } from "react-redux";
import { isMobile } from "react-device-detect";

import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import { SUPPORTED_WALLETS } from "./constants";

import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core";
import { injected, walletconnect } from "features/web3/connectors";
import { walletConnectSuccess } from "state/wallet/walletSlice";

type Props = DialogProps & {
  title: string;
  onConnected: () => void;
};
// ✔️ create the dialog you want to use
const BCWeb3Modal: React.FC<Props> = ({ title, onConnected, ...props }) => {
  const { active, account, library, connector, activate, deactivate } =
    useWeb3React();
  const dispatch = useDispatch();
  async function connect(connector: AbstractConnector) {
    try {
      await activate(connector);
    } catch (ex) {
      console.log(ex);
    }
  }

  /* async function disconnect() {
    try {
      deactivate();
    } catch (ex) {
      console.log(ex);
    }
  }

  const handleWalletConnect = async () => {
    console.log("active", active);
    await connect(walletconnect);
    // console.log("account", library, onConnected);
  }; */
  const tryActivation = async (
    connector:
      | (() => Promise<AbstractConnector>)
      | AbstractConnector
      | undefined
  ) => {
    let conn = typeof connector === "function" ? await connector() : connector;
    // activate(conn)
    conn !== undefined &&
      activate(conn, undefined, true).catch((error) => {
        if (error instanceof UnsupportedChainIdError) {
          // activate(conn) // a little janky...can't use setError because the connector isn't set
        }
      });
  };

  // get wallets user can switch too, depending on device/browser
  function getOptions() {
    const isMetamask = window.ethereum && window.ethereum.isMetaMask;

    return Object.keys(SUPPORTED_WALLETS).map((key) => {
      const option = SUPPORTED_WALLETS[key];

      if (isMobile) {
        if (!window.web3 && !window.ethereum && option.mobile) {
          return (
            <ListItem
              alignItems="flex-start"
              button
              onClick={() => tryActivation(option.connector)}
            >
              <ListItemAvatar>
                <Avatar alt={option.name} src={option.iconName} />
              </ListItemAvatar>
              <ListItemText
                primary={option.name}
                secondary={
                  <React.Fragment>
                    {/* <Typography
                        component="span"
                        variant="body2"
                        color="primary"
                      >
                        opt 1
                      </Typography>
                    */}
                    {option.description}
                  </React.Fragment>
                }
              />
            </ListItem>
          );
        }
        return null;
      }
      // overwrite injected when needed
      if (option.connector === injected) {
        // don't show injected if there's no injected provider
        if (!(window.web3 || window.ethereum)) {
          if (option.name === "MetaMask") {
            return (
              <ListItem
                alignItems="flex-start"
                button
                onClick={() => tryActivation(option.connector)}
              >
                <ListItemAvatar>
                  <Avatar alt={option.name} src={option.iconName} />
                </ListItemAvatar>
                <ListItemText
                  primary={option.name}
                  secondary={
                    <React.Fragment>
                      {/* <Typography
                          component="span"
                          variant="body2"
                          color="primary"
                        >
                          opt-2
                        </Typography>
                      */}
                      {option.description}
                    </React.Fragment>
                  }
                />
              </ListItem>
            );
          } else {
            return null; // dont want to return install twice
          }
        }
        // don't return metamask if injected provider isn't metamask
        else if (option.name === "MetaMask" && !isMetamask) {
          return null;
        }
        // likewise for generic
        else if (option.name === "Injected" && isMetamask) {
          return null;
        }
      }
      // return rest of options
      return (
        !isMobile &&
        !option.mobileOnly && (
          <ListItem
            alignItems="flex-start"
            button
            onClick={() => tryActivation(option.connector)}
          >
            <ListItemAvatar>
              <Avatar alt={option.name} src={option.iconName} />
            </ListItemAvatar>
            <ListItemText
              primary={option.name}
              secondary={
                <React.Fragment>
                  {/* <Typography
                      component="span"
                      variant="body2"
                      color="primary"
                    >
                      opt-3
                    </Typography>
                  */}
                  {option.description}
                </React.Fragment>
              }
            />
          </ListItem>
        )
      );
    });
  }

  const initialize = useCallback(
    async function () {
      // const web3 = new Web3(library);
      const web3 = library;
      const accounts = await library.eth.getAccounts();
      const address = accounts[0];
      const networkId = await web3.eth.getChainId();
      dispatch(
        walletConnectSuccess({
          web3,
          address,
          networkId,
          ...{ connected: true, connectWalletPending: true },
        })
      );
      onConnected();
    },
    [dispatch, library, onConnected]
  );

  useEffect(() => {
    if (active && library) {
      initialize();
    }
  }, [active, library, initialize]);

  const handleMetamaskConnect = () => {
    connect(injected);
  };

  return (
    <Dialog {...props}>
      <DialogTitle>{title}</DialogTitle>
      <List>{getOptions()}</List>
    </Dialog>
  );
};

export default BCWeb3Modal;
