import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

// import { all, put, takeLatest } from "redux-saga/effects";
import { approval } from "features/web3/approval";
import {
  fetchVaultApprovalBegin,
  fetchVaultApprovalSuccess,
  fetchApproval,
  fetchVaultApprovalFailed,
} from "./vaultSlice";
import { RootState } from "state/store";

export function* fetchApprovalSaga(action: ReturnType<typeof fetchApproval>) {
  let { address, web3, tokenAddress, contractAddress, tokenSymbol } =
    action.payload;
  try {
    yield fetchVaultApprovalBegin(tokenSymbol);

    const data: any = yield approval({
      web3,
      address,
      tokenAddress,
      contractAddress,
    });

    console.log("data", data);
    fetchVaultApprovalSuccess({ tokenSymbol, data, contractAddress });
  } catch (error) {
    console.log("error", error);
    fetchVaultApprovalFailed(tokenSymbol);
  }

  /* const promise = new Promise((resolve, reject) => {
      approval({
        web3,
        address,
        tokenAddress,
        contractAddress,
        dispatch,
      })
        .then((data) => {
          dispatch({
            type: VAULT_FETCH_APPROVAL_SUCCESS,
            data: { allowance: data },
            spender: contractAddress,
            tokenSymbol,
          });
          resolve();
        })
        .catch((error) => {
          dispatch({
            type: VAULT_FETCH_APPROVAL_FAILURE,
            tokenSymbol,
          });
          reject(error.message || error);
        });
    });

    return promise;
  };*/
}

export function useFetchApproval() {
  const dispatch = useDispatch();

  const { fetchApprovalPending } = useSelector((state: RootState) => ({
    fetchApprovalPending: state.vault.fetchApprovalPending,
  }));

  const boundAction = useCallback(
    (data) => dispatch(fetchApproval(data)),
    [dispatch]
  );

  return {
    fetchApproval: boundAction,
    fetchApprovalPending,
  };
}

/*export function reducer(state, action) {
  switch (action.type) {
    case VAULT_FETCH_APPROVAL_BEGIN:
      return {
        ...state,
        fetchApprovalPending: {
          ...state.fetchApprovalPending,
          [action.tokenSymbol]: true,
        },
      };

    case VAULT_FETCH_APPROVAL_SUCCESS:
      const { tokens } = state;
      tokens[action.tokenSymbol].allowance[action.spender] = action.data.allowance;
      return {
        ...state,
        tokens,
        fetchApprovalPending: {
          ...state.fetchApprovalPending,
          [action.tokenSymbol]: false,
        },
      };

    case VAULT_FETCH_APPROVAL_FAILURE:
      return {
        ...state,
        fetchApprovalPending: {
          ...state.fetchApprovalPending,
          [action.tokenSymbol]: false,
        },
      };

    default:
      return state;
  }
}*/
