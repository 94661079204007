import { createTheme } from "@material-ui/core/styles";

const createThemeMode = (isNightMode) =>
  createTheme({
    palette: {
      type: isNightMode ? "dark" : "light",
      background: {
        default: isNightMode ? "#192231" : "#98aabf",
        paper: isNightMode ? "#606077" : "#98aabf",
        primary: isNightMode ? "#505067" : "#BAC8D7",
        secondary: isNightMode ? "#3B3A4D" : "#E5EAEF",
        extra: isNightMode ? "#242332" : "#E5EAEF",
        dark: isNightMode ? "#2B2A3D" : "#999",
        paused: isNightMode ? "#2B2A5A" : "#FCE57E",
        retired: isNightMode ? "#d32f2f" : "#e57373",
        hover: isNightMode ? "#2B2A3D" : "#EFE6DC",
        border: isNightMode ? "#2B2A3D" : "#DED9D5",
        overlay: isNightMode
          ? "rgba(0, 0, 0, 0.75)"
          : "rgba(255, 255, 255, 0.75)",
      },
      primary: {
        main: isNightMode ? "#fff" : "#000",
      },
      secondary: {
        main: isNightMode ? "#fff" : "#E5EAEF",
      },
      text: {
        primary: isNightMode ? "#fff" : "#000",
        secondary: isNightMode ? "#B0B0DD" : "#00000066",
      },
    },
    overrides: {
      MuiButton: {
        label: {
          color: isNightMode ? "#fff" : "#000",
        },
      },
      // for dropdown menu items
      MuiButtonBase: {
        root: {
          color: isNightMode ? "#fff" : "#000",
        },
      },
      MuiCheckbox: {
        colorPrimary: {
          color: isNightMode ? "#fff" : "#000",
        },
        colorSecondary: {
          color: isNightMode ? "#fff" : "#000",
        },
      },
    },
  });

export default createThemeMode;
