import { all, put, takeLatest } from "redux-saga/effects";
import Web3 from "web3";
import BCWeb3Modal from "features/web3/web3Modal";
import {
  walletConnectBegin,
  walletConnectSuccess,
  walletConnectFailed,
  connectWallet,
  disconnectWallet,
  walletDisconnectBegin,
  walletDisconnectSuccess,
  walletDisconnectFailed,
} from "./walletSlice";

export function* connectWalletSaga(action: ReturnType<typeof connectWallet>) {
  // const web3Modal = action.payload;
  // console.log("connectWalletSaga called", action.payload);
  const showModal = action.payload;

  yield put(walletConnectBegin());
  try {
    const modal = showModal(BCWeb3Modal, {
      title: "Wallets",
      onConnected: () => {
        modal.hide();
      },
    });
    /* const provider = yield web3Modal?.connect();
    const web3 = new Web3(provider);
     web3.eth.extend({
          methods: [
            {
              name: "chainId",
              call: "eth_chainId",
              outputFormatter: web3.utils.hexToNumber as unknown as () => void, //TODO
            },
          ],
        });

    const accounts = await web3.eth.getAccounts();
    const address = accounts[0];
    const accounts = yield web3.eth.getAccounts();
        const address = accounts[0];
        let networkId = yield web3.eth.getChainId();
    if (networkId === 44787) {
      // Trust provider returns an incorrect chainId for BSC.
      networkId = 44787;
    }

    console.log("connected", accounts, address, networkId);
    yield put(
      walletConnectSuccess({
        web3,
        address,
        networkId,
        ...{ connected: true, connectWalletPending: true },
      })
    ); */
  } catch (error) {
    yield put(walletConnectFailed());
  }
}

export function* disconnectWalletSaga(
  action: ReturnType<typeof disconnectWallet>
) {
  const { web3 } = action?.payload;
  // yield put(walletDisconnectBegin());
  try {
    // console.log("web3", web3, web3?.currentProvider);
    // if (web3 && web3.currentProvider && web3.currentProvider.close) {
    //   yield web3.currentProvider.close();
    // }
    // yield web3Modal?.clearCachedProvider();
    yield put(walletDisconnectSuccess());
  } catch (error) {
    yield put(walletDisconnectFailed());
  }
}

export default function* walletSaga() {
  yield all([
    takeLatest(connectWallet, connectWalletSaga),
    takeLatest(disconnectWallet, disconnectWalletSaga),
  ]);
}
