import React from "react";
import ReactDOM from "react-dom";
// import { HashRouter, Route, Switch } from "react-router-dom";
import "./i18n";

import "./index.css";
import App from "./features/home/App";
import { store } from "state/store";
import { Provider } from "react-redux";
import { Web3ReactProvider } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import Web3 from "web3";
import {
  makeStyles,
  ThemeProvider,
  StylesProvider,
} from "@material-ui/core/styles";
import * as serviceWorker from "./serviceWorker";
import network from "./network";
import Root from "./Root";
import ModalProvider from "mui-modal-provider";

if (!network) {
  console.log("no web3");
}

function getLibrary(provider: any) {
  // const library = new Web3Provider(provider);
  // library.pollingInterval = 12000;
  return new Web3(provider);
  // return library;
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Web3ReactProvider getLibrary={getLibrary}>
        <StylesProvider injectFirst>
          <ModalProvider>
            <Root />
          </ModalProvider>
        </StylesProvider>
      </Web3ReactProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
