import { all, put, takeLatest } from "redux-saga/effects";
import Web3 from "web3";
import { getRpcUrl } from "common/networkSetup";
import { MultiCall } from "eth-multicall";
import StrategyArtifact from "features/configure/contracts/StrategyUbeSwap.json";
import { getNetworkMulticall } from "features/helpers/getNetworkData";
import { byDecimals } from "features/helpers/bignumber";
import BigNumber from "bignumber.js";

import {
  fetchVaultDataBegin,
  fetchVaultDataSuccess,
  fetchVaultDataFailed,
  fetchVaultsData,
  fetchApproval,
} from "./vaultSlice";
import { fetchApprovalSaga } from "./fetchApproval";

// import { resolve } from "dns";

export function* fetchVaultsDataSaga(
  action: ReturnType<typeof fetchVaultsData>
) {
  yield fetchVaultDataBegin();
  let { web3, pools } = action.payload;

  if (!web3) {
    // setup default provider to get vault data
    web3 = new Web3(new Web3.providers.HttpProvider(getRpcUrl()));
  }

  const multicall = new MultiCall(web3, getNetworkMulticall());
  const vaultCalls = pools.map((pool: { strategyAddress: string }) => {
    const vault = new web3.eth.Contract(
      StrategyArtifact.abi,
      pool.strategyAddress
    );
    return {
      //pricePerFullShare: 1, //vault.methods.getPricePerFullShare(),
      tvl: vault.methods.wantLockedTotal(),
    };
  });

  Promise.all([
    multicall.all([vaultCalls]).then((result) => result[0]),
    // whenPricesLoaded(), // need to wait until prices are loaded in cache
  ])
    // Promise.all([
    //   setTimeout(() => {
    //     return 100;
    //   }, 1000),
    // ])
    .then((data) => {
      const newPools = pools.map(
        (pool: { tokenDecimals: number | undefined }, i: number | number) => {
          /*const pricePerFullShare = byDecimals(
          data[0][i].pricePerFullShare,
          18
        ).toNumber();*/

          console.log("data", data[0]);
          const pricePerFullShare = new BigNumber(1);
          return {
            pricePerFullShare: new BigNumber(pricePerFullShare).toNumber() || 1,
            tvl: byDecimals(data[0][i].tvl, pool.tokenDecimals).toNumber(),
            oraclePrice: 1, //fetchPrice({ id: pool.oracleId }) || 0,
          };
        }
      );
      // dispatch({
      //   type: VAULT_FETCH_VAULTS_DATA_SUCCESS,
      //   data: newPools,
      // });
      fetchVaultDataSuccess(newPools);
    })
    .catch((error) => {
      // dispatch({
      //   type: VAULT_FETCH_VAULTS_DATA_FAILURE,
      // });
      console.log("fetchVaultDataFailed");
      fetchVaultDataFailed();
    });
}

export default function* vaultSaga() {
  yield all([
    takeLatest(fetchVaultsData, fetchVaultsDataSaga),
    takeLatest(fetchApproval, fetchApprovalSaga),
  ]);
}
