import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";

const POLLING_INTERVAL = 12000;
const RPC_URLS: { [chainId: number]: string } = {
  1: process.env.RPC_URL_1 as string,
  4: process.env.RPC_URL_4 as string,
  42220: "https://forno.celo.org",
};

export const walletconnect = new WalletConnectConnector({
  rpc: { 42220: RPC_URLS[42220] },
  qrcode: true,
});
export const injected = new InjectedConnector({
  supportedChainIds: [1, 3, 4, 5, 42, 42220],
});
