export const celoPools = [
  {
    id: "ubeswap-celo-sol-celo",
    name: "SOL CELO LP",
    token: "SOL CELO  LP ",
    tokenDescription: "Ubeswap",
    tokenAddress: "0x01522C42cA43D7BfD34f373d41AD7d90a95E714C",
    tokenDecimals: 18,
    tokenDescriptionUrl: "#",
    earnedToken: "celo-sol-celo",
    earnedTokenAddress: "0x7036f519EC6EFf4414b26c53283F6Be6650C263e",
    earnContractAddress: "0x7036f519EC6EFf4414b26c53283F6Be6650C263e",
    pricePerFullShare: 1,
    tvl: 0,
    oracle: "lps",
    oracleId: "ubeswap-celo-sol-celo",
    oraclePrice: 0,
    depositsPaused: false,
    status: "active",
    platform: "Ubeswap",
    assets: ["SOL", "CELO"],
    withdrawalFee: "0.01%",
    addLiquidityUrl:
      "https://app.ubeswap.org/#/add/0x173234922eB27d5138c5e481be9dF5261fAeD450/0x471EcE3750Da237f93B8E339c536989b8978a438",
    buyTokenUrl:
      "https://info.ubeswap.org/pair/0x01522c42ca43d7bfd34f373d41ad7d90a95e714c",
  },
  {
    id: "ubeswap-celo-moo-mcelo",
    name: "MOO mCELO LP",
    token: "MOO mCELO  LP ",
    tokenDescription: "Ubeswap",
    tokenAddress: "0x9272388FDf2D6bFbA8b1Cdd99732A3D552a71346",
    tokenDecimals: 18,
    tokenDescriptionUrl: "#",
    earnedToken: "celo-moo-mcelo",
    earnedTokenAddress: "0xBb376142614442846dAbEe6Db6A178beEEbA6873",
    earnContractAddress: "0xBb376142614442846dAbEe6Db6A178beEEbA6873",
    pricePerFullShare: 1,
    tvl: 0,
    oracle: "lps",
    oracleId: "ubeswap-celo-moo-mcelo",
    oraclePrice: 0,
    depositsPaused: false,
    status: "active",
    platform: "Ubeswap",
    assets: ["MOO", "mCELO"],
    withdrawalFee: "0.01%",
    addLiquidityUrl:
      "https://app.ubeswap.org/#/add/0x17700282592D6917F6A73D0bF8AcCf4D578c131e/0x7D00cd74FF385c955EA3d79e47BF06bD7386387D",
    buyTokenUrl:
      "https://info.ubeswap.org/pair/0x9272388fdf2d6bfba8b1cdd99732a3d552a71346",
  },
  {
    id: "ubeswap-celo-avax-mcusd",
    name: "AVAX mcUSD LP",
    token: "AVAX mcUSD  LP ",
    tokenDescription: "Ubeswap",
    tokenAddress: "0x11Ce57AaAE0418d120e4415691B6F174219e4b02",
    tokenDecimals: 18,
    tokenDescriptionUrl: "#",
    earnedToken: "celo-avax-mcusd",
    earnedTokenAddress: "0x4a87da7666F4997d8b2F581787cb2C4DC470E5DB",
    earnContractAddress: "0x4a87da7666F4997d8b2F581787cb2C4DC470E5DB",
    pricePerFullShare: 1,
    tvl: 0,
    oracle: "lps",
    oracleId: "ubeswap-celo-avax-mcusd",
    oraclePrice: 0,
    depositsPaused: false,
    status: "active",
    platform: "Ubeswap",
    assets: ["AVAX", "mcUSD"],
    withdrawalFee: "0.01%",
    addLiquidityUrl:
      "https://app.ubeswap.org/#/add/0x8E3670FD7B0935d3FE832711deBFE13BB689b690/0x918146359264C492BD6934071c6Bd31C854EDBc3",
    buyTokenUrl:
      "https://info.ubeswap.org/pair/0x11ce57aaae0418d120e4415691b6f174219e4b02",
  },
  {
    id: "ubeswap-celo-source-mcusd",
    name: "SOURCE mcUSD  LP",
    token: "SOURCE mcUSD  LP",
    tokenDescription: "Ubeswap",
    tokenAddress: "0x1265bb3e69968A14fF7c4d8204b17c6B79A7FF52",
    tokenDecimals: 18,
    tokenDescriptionUrl: "#",
    earnedToken: "ube-source-mcusd",
    earnedTokenAddress: "0x0ea231fc89A7D3D5Bf75e3ac423FD340EC155c7c",
    earnContractAddress: "0x0ea231fc89A7D3D5Bf75e3ac423FD340EC155c7c",
    pricePerFullShare: 1,
    tvl: 0,
    oracle: "lps",
    oracleId: "ubeswap-celo-source-mcusd",
    oraclePrice: 0,
    depositsPaused: false,
    status: "active",
    platform: "Ubeswap",
    assets: ["SOURCE", "mcUSD"],
    withdrawalFee: "0.01%",
    addLiquidityUrl:
      "https://app.ubeswap.org/#/add/0x74c0C58B99b68cF16A717279AC2d056A34ba2bFe/0x918146359264C492BD6934071c6Bd31C854EDBc3",
    buyTokenUrl:
      "https://info.ubeswap.org/pair/0x1265bb3e69968a14ff7c4d8204b17c6b79a7ff52",
  },
  {
    id: "ubeswap-celo-pact-celo",
    name: "PACT CELO  LP",
    token: "PACT CELO  LP",
    tokenDescription: "Ubeswap",
    tokenAddress: "0x39AC98447f28612D3583e46E57cb106337FCAe3F",
    tokenDecimals: 18,
    tokenDescriptionUrl: "#",
    earnedToken: "ube-pact-celo",
    earnedTokenAddress: "0x221702dc6913F203780BEF4186AbE88e43a3Bd9D",
    earnContractAddress: "0x221702dc6913F203780BEF4186AbE88e43a3Bd9D",
    pricePerFullShare: 1,
    tvl: 0,
    oracle: "lps",
    oracleId: "ubeswap-celo-pact-celo",
    oraclePrice: 0,
    depositsPaused: false,
    status: "active",
    platform: "Ubeswap",
    assets: ["PACT", "CELO"],
    withdrawalFee: "0.01%",
    addLiquidityUrl:
      "https://app.ubeswap.org/#/add/0x46c9757C5497c5B1f2eb73aE79b6B67D119B0B58/0x471EcE3750Da237f93B8E339c536989b8978a438",
    buyTokenUrl:
      "https://info.ubeswap.org/pair/0x39ac98447f28612d3583e46e57cb106337fcae3f",
  },
  {
    id: "ubeswap-celo-ari-celo",
    name: "ARI CELO  LP",
    token: "ARI CELO  LP",
    tokenDescription: "Ubeswap",
    tokenAddress: "0x65596e0f281b0CBcba0aA261982690E9BCBa7247",
    tokenDecimals: 18,
    tokenDescriptionUrl: "#",
    earnedToken: "ube-ari-celo",
    earnedTokenAddress: "0xBc99E5DF9C583F0721A54bA9deB89d3775e33218",
    earnContractAddress: "0xBc99E5DF9C583F0721A54bA9deB89d3775e33218",
    pricePerFullShare: 1,
    tvl: 0,
    oracle: "lps",
    oracleId: "ubeswap-celo-ari-celo",
    oraclePrice: 0,
    depositsPaused: false,
    status: "active",
    platform: "Ubeswap",
    assets: ["ARI", "CELO"],
    withdrawalFee: "0.01%",
    addLiquidityUrl:
      "https://app.ubeswap.org/#/add/0x20677d4f3d0F08e735aB512393524A3CfCEb250C/0x471EcE3750Da237f93B8E339c536989b8978a438",
    buyTokenUrl:
      "https://info.ubeswap.org/pair/0x65596e0f281b0cbcba0aa261982690e9bcba7247",
  },
  {
    id: "sushi-celo-cusd-usdc",
    name: "cUSD USDC LP",
    token: "cUSD USDC LP",
    tokenDescription: "Sushi",
    tokenAddress: "0xA364EdE5590B46E618527d7535506743D978bBF2",
    tokenDecimals: 18,
    tokenDescriptionUrl: "#",
    earnedToken: "sushi-cusd-usdc",
    earnedTokenAddress: "0xCA99D0cfc8b4f9881bdAB084542d58BF24Dd7657",
    earnContractAddress: "0xCA99D0cfc8b4f9881bdAB084542d58BF24Dd7657",
    pricePerFullShare: 1,
    tvl: 0,
    oracle: "lps",
    oracleId: "sushi-celo-cusd-usdc",
    oraclePrice: 0,
    depositsPaused: false,
    status: "active",
    platform: "Sushi",
    assets: ["cUSD", "USDC"],
    withdrawalFee: "0%",
    addLiquidityUrl:
      "https://app.sushi.com/add/0x765DE816845861e75A25fCA122bb6898B8B1282a/0xef4229c8c3250C675F21BCefa42f58EfbfF6002a",
    buyTokenUrl: "",
  },
  {
    id: "ubeswap-celo-mcusd-mceur",
    name: "mcUSD mcEUR LP",
    token: "mcUSD mcEUR LP",
    tokenDescription: "Ubeswap",
    tokenAddress: "0xF94fEA0C87D2b357DC72b743b45A8cB682b0716E",
    tokenDecimals: 18,
    tokenDescriptionUrl: "#",
    earnedToken: "ubeswap-mcusd-mceur",
    earnedTokenAddress: "0x2eCEC7afD95AD402c69a4EA5AFeE49695e1e80a4",
    earnContractAddress: "0x2eCEC7afD95AD402c69a4EA5AFeE49695e1e80a4",
    pricePerFullShare: 1,
    tvl: 0,
    oracle: "lps",
    oracleId: "ubeswap-celo-mcusd-mceur",
    oraclePrice: 0,
    depositsPaused: false,
    status: "active",
    platform: "Ubeswap",
    assets: ["mcUSD", "mcEUR"],
    withdrawalFee: "0.01%",
    addLiquidityUrl:
      "https://app.ubeswap.org/#/add/0x918146359264C492BD6934071c6Bd31C854EDBc3/0xE273Ad7ee11dCfAA87383aD5977EE1504aC07568",
    buyTokenUrl:
      "https://info.ubeswap.org/pair/0xf94fea0c87d2b357dc72b743b45a8cb682b0716e",
  },
  {
    id: "ubeswap-celo-mcusd-immo",
    name: "mcUSD IMMO LP",
    token: "mcUSD IMMO LP",
    tokenDescription: "Ubeswap",
    tokenAddress: "0x7d63809EBF83EF54c7CE8dEd3591D4E8Fc2102eE",
    tokenDecimals: 18,
    tokenDescriptionUrl: "#",
    earnedToken: "ubeswap-mcusd-immo",
    earnedTokenAddress: "0x664092b9d48612f53F2995205DEDc6F21eff9770",
    earnContractAddress: "0x664092b9d48612f53F2995205DEDc6F21eff9770",
    pricePerFullShare: 1,
    tvl: 0,
    oracle: "lps",
    oracleId: "ubeswap-celo-mcusd-immo",
    oraclePrice: 0,
    depositsPaused: false,
    status: "active",
    platform: "Ubeswap",
    assets: ["mcUSD", "IMMO"],
    withdrawalFee: "0.01%",
    addLiquidityUrl:
      "https://app.ubeswap.org/#/farm/0x918146359264C492BD6934071c6Bd31C854EDBc3/0xE685d21b7B0FC7A248a6A8E03b8Db22d013Aa2eE/0xf01E43ebEC5aD24F8a8A9dDf78bf42186E158ED2",
    buyTokenUrl:
      "https://info.ubeswap.org/pair/0x7d63809EBF83EF54c7CE8dEd3591D4E8Fc2102eE",
  },
];
