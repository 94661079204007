import { injected, walletconnect } from "./connectors";

import { AbstractConnector } from "@web3-react/abstract-connector";
import WALLETCONNECT from "images/WALLETCONNECT.png";
import METAMASK from "images/METAMASK.png";
import VALORA from "images/VALORA.png";

const ChainId = {
  CELO: "42220",
};

export const RPC = {
  [ChainId.CELO]: "https://forno.celo.org",
};

export const POOL_DENY = ["14", "29", "45", "30"];

// Block time here is slightly higher (~1s) than average in order to avoid ongoing proposals past the displayed time
export const AVERAGE_BLOCK_TIME_IN_SECS = 13;

// export const COMMON_CONTRACT_NAMES: { [address: string]: string } = {
//     // [UNI_ADDRESS]: 'UNI',
//     [TIMELOCK_ADDRESS]: 'Timelock',
// }

export interface WalletInfo {
  connector?: (() => Promise<AbstractConnector>) | AbstractConnector;
  name: string;
  iconName: string;
  description: string;
  href: string | null;
  color: string;
  primary?: true;
  mobile?: true;
  mobileOnly?: true;
}

export const SUPPORTED_WALLETS: { [key: string]: WalletInfo } = {
  INJECTED: {
    connector: injected,
    name: "Injected",
    iconName: METAMASK,
    description: "Injected web3 provider.",
    href: null,
    color: "#010101",
    primary: true,
  },
  METAMASK: {
    connector: injected,
    name: "MetaMask",
    iconName: METAMASK,
    description: "Browser extension.",
    href: null,
    color: "#E8831D",
  },
  WALLET_CONNECT: {
    connector: walletconnect,
    name: "WalletConnect",
    iconName: WALLETCONNECT,
    description: "Valora, Metamask...",
    href: null,
    color: "#4196FC",
    mobile: true,
  },
  VALORA: {
    connector: walletconnect,
    name: "Valora",
    iconName: VALORA,
    description: "Connect to Valora a mobile payment app",
    href: null,
    color: "#4196FC",
    mobile: true,
    mobileOnly: true,
  },
};

export const NetworkContextName = "NETWORK";

// default allowed slippage, in bips
export const INITIAL_ALLOWED_SLIPPAGE = 50;
// 30 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 30;
