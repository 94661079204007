/* eslint-disable import/first */

declare global {
  interface Window {
    REACT_APP_NETWORK_ID: any;
  }
}

export const allNetworks = [
  {
    name: "CELO",
    asset: "CELO",
    id: 42220,
    hash: "/celo",
  },
  {
    name: "alfajores",
    asset: "CELO",
    id: 44787,
    hash: "/alfajores",
  },
];

const network = allNetworks.find((n) =>
  window.location.hash.startsWith("#" + n.hash)
);

if (!network) {
  window.location.hash = allNetworks[0].hash;
  window.location.reload();
} else {
  window.REACT_APP_NETWORK_ID = network.id;
}

export default network;
