import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "state/store";
import Web3Modal from "web3modal";
import Web3 from "web3";

export interface WalletState {
  address: string;
  web3: any;
  connected: boolean;
  networkId: number;
  connectWalletPending: boolean;
}

const initialState = {
  address: "",
  web3: null,
  connected: false,
  networkId: window.REACT_APP_NETWORK_ID,
  connectWalletPending: false,
  disconnectWalletPending: false,
};
//{ web3: any; networkId: number; address: string }
export const walletSlice = createSlice({
  name: "home",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    walletConnectBegin: (state) => {
      state.connectWalletPending = true;
    },
    walletConnectSuccess: (state, wallet: PayloadAction<WalletState>) => {
      const { web3, networkId, address, connected } = wallet.payload;
      state.web3 = web3;
      state.address = address;
      state.networkId = networkId;
      state.connected = connected;
      // state = wallet.payload;
    },
    walletConnectFailed: (state) => {
      state.connectWalletPending = false;
    },
    connectWallet: (state, web3Modal: PayloadAction<any>) => {},
    walletDisconnectBegin: (state) => {
      state.disconnectWalletPending = true;
    },
    walletDisconnectSuccess: (state) => {
      state.address = "";
      state.web3 = null;
      state.connected = false;
      state.disconnectWalletPending = true;
    },
    walletDisconnectFailed: (state) => {
      state.address = "";
      state.web3 = null;
      state.connected = false;
      state.disconnectWalletPending = false;
    },
    disconnectWallet: (
      state,
      data: PayloadAction<{ web3: Web3 | null; web3Modal: Web3Modal | null }>
    ) => {},
  },
});

export const {
  walletConnectBegin,
  walletConnectSuccess,
  walletConnectFailed,
  connectWallet,
  disconnectWallet,
  walletDisconnectBegin,
  walletDisconnectSuccess,
  walletDisconnectFailed,
} = walletSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

export const selectWallet = (state: RootState) => state.wallet;

export default walletSlice.reducer;
