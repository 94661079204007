import { useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { connectWallet, disconnectWallet } from "./walletSlice";
import { RootState } from "state/store";

export function useConnectWallet() {
  const dispatch = useDispatch();
  const { web3, address, networkId, connected, connectWalletPending } =
    useSelector(
      (state: RootState) => ({
        web3: state.wallet.web3,
        address: state.wallet.address,
        networkId: state.wallet.networkId,
        connected: state.wallet.connected,
        connectWalletPending: state.wallet.connectWalletPending,
      }),
      shallowEqual
    );
  const boundAction = useCallback(
    (data) => dispatch(connectWallet(data)),
    [dispatch]
  );

  return {
    web3,
    address,
    networkId,
    connected,
    connectWalletPending,
    connectWallet: boundAction,
  };
}

export function useDisconnectWallet() {
  const dispatch = useDispatch();
  const { disconnectWalletPending } = useSelector(
    (state: RootState) => ({
      disconnectWalletPending: state.wallet.disconnectWalletPending,
    }),
    shallowEqual
  );
  const boundAction = useCallback(
    (web3, web3Modal) => dispatch(disconnectWallet({ web3, web3Modal })),
    [dispatch]
  );

  return {
    disconnectWalletPending,
    disconnectWallet: boundAction,
  };
}
