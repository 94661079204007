import { container } from "assets/jss/material-kit-pro-react.js";

const appStyle = (theme) => ({
  "@global": {
    "html,body": {
      backgroundColor: theme.palette.background.default,
    },
  },
  page: {
    backgroundColor: theme.palette.background.default,
    overflow: "hidden",
    position: "relative",
  },
  container: {
    ...container,
    zIndex: 1,
  },
  children: {
    minHeight: "77vh",
  },
});

export default appStyle;
