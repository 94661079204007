const styles = (theme) => ({
  container: {
    marginBottom: "24px",
  },
  accordion: {
    width: "100%",
    backgroundColor: theme.palette.background.primary,
  },
  divider: {
    margin: "0 30px",
  },
});

export default styles;
