import React from "react";

import Pools from "features/vault/components/Pools/Pools";
import Disclaimer from "components/Disclaimer/Disclaimer";
import Button from "@material-ui/core/Button";
import Home from "./walletconnect";
import {
  readContract,
  multiCallContract,
  stake,
} from "./contractTest/testContract";
import { useConnectWallet } from "../../state/wallet/hooks";

export default function HomePage() {
  const { web3 } = useConnectWallet();
  return (
    <>
      <Disclaimer />

      <Pools fromPage="home" />
      {/* <Button
        variant="contained"
        onClick={() => {
          stake(web3);
        }}
      >
        contract
      </Button> */}
    </>
  );
}
