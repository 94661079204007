import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "state/store";

import initialState from "./initialState";

export interface VaultState {
  // TODO
}

//{ web3: any; networkId: number; address: string }
export const vaultSlice = createSlice({
  name: "vault",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    fetchVaultDataBegin: (state) => {
      state.fetchVaultsDataDone = false;
      state.fetchVaultsDataPending = true;
    },
    fetchVaultDataSuccess: (state, newPools: any) => {
      // const { web3, networkId, address, connected } = newPools.payload;
      console.log("newPools.payload", newPools.payload);

      state.pools = { ...state, ...newPools.payload };
    },
    fetchVaultDataFailed: (state) => {
      state.fetchVaultsDataPending = false;
    },
    fetchVaultsData: (state, param) => {},
    fetchApproval: (state, param) => {},

    fetchVaultApprovalBegin: (state, tokenSymbol: PayloadAction<string>) => {
      state.fetchApprovalPending = { [tokenSymbol.payload]: true };
    },

    fetchVaultApprovalSuccess: (state, tokenSymbol: PayloadAction<any>) => {
      console.log("action.payload", tokenSymbol.payload);

      /* state.tokens = [action.tokenSymbol].allowance[action.spender] =
         action.data.allowance;*/
      // const tokenSymbol = {
      //   [action.payload.tokenSymbol]: { allowance : {action.payload.spender: action.payload.data.allowance}},
      // };
      // state.tokens = [action.tokenSymbol].allowance[action.spender];
    },
    fetchVaultApprovalFailed: (state, tokenSymbol: PayloadAction<string>) => {
      state.fetchApprovalPending = false;
      state.fetchApprovalPending = { [tokenSymbol.payload]: false };
    },
  },
});

export const {
  fetchVaultDataBegin,
  fetchVaultDataSuccess,
  fetchVaultDataFailed,
  fetchVaultsData,
  fetchApproval,
  fetchVaultApprovalBegin,
  fetchVaultApprovalSuccess,
  fetchVaultApprovalFailed,
} = vaultSlice.actions;

export const selectVault = (state: RootState) => state.vault;

export default vaultSlice.reducer;
