export const nativeCoins = [
  {
    chainId: 42220,
    name: "Celo",
    symbol: "CELO",
    decimals: 18,
    wrappedSymbol: "CELO",
    allowance: Infinity,
  },
  {
    chainId: 44787,
    name: "alfajores",
    symbol: "CELO",
    decimals: 18,
    wrappedSymbol: "WCELO",
    allowance: Infinity,
  },
  {
    chainId: 1,
    name: "Ethereum",
    symbol: "ETH",
    decimals: 18,
    wrappedSymbol: "WETH",
    allowance: Infinity,
  },
  {
    chainId: 56,
    name: "Binance Coin",
    symbol: "BNB",
    decimals: 18,
    wrappedSymbol: "WBNB",
    allowance: Infinity,
  },
  {
    chainId: 128,
    name: "Heco Token",
    symbol: "HT",
    decimals: 18,
    wrappedSymbol: "WHT",
    allowance: Infinity,
  },
  {
    chainId: 43114,
    name: "Avalance Coin",
    symbol: "AVAX",
    decimals: 18,
    wrappedSymbol: "WAVAX",
    allowance: Infinity,
  },
  {
    chainId: 137,
    name: "Polygon",
    symbol: "MATIC",
    decimals: 18,
    wrappedSymbol: "WMATIC",
    allowance: Infinity,
  },
  {
    chainId: 250,
    name: "Fantom",
    symbol: "FTM",
    decimals: 18,
    wrappedSymbol: "WFTM",
    allowance: Infinity,
  },
  {
    chainId: 1666600000,
    name: "Harmony",
    symbol: "ONE",
    decimals: 18,
    wrappedSymbol: "WONE",
    allowance: Infinity,
  },
  {
    chainId: 42161,
    name: "Arbitrum",
    symbol: "ETH",
    decimals: 18,
    wrappedSymbol: "WETH",
    allowance: Infinity,
  },
];
