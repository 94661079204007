import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { SnackbarProvider } from "notistack";
// import { useLocation } from "react-router";
import { NetworkConnectNotice } from "components/NetworkConnectNotice/NetworkConnectNotice";
import {
  useConnectWallet,
  useDisconnectWallet,
} from "../../state/wallet/hooks";
import { createWeb3Modal } from "features/web3/createWeb3Modal";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";

import { useModal } from "mui-modal-provider";
import { useWeb3React } from "@web3-react/core";

import createThemeMode from "./jss/appTheme";
import appStyle from "./jss/appStyle.js";
import useNightMode from "./hooks/useNightMode";

import Header from "components/Header/Header";
import HeaderLinks from "components/HeaderLinks/HeaderLinks";

const themes = { light: null, dark: null };
const getTheme = (mode) => {
  return (themes[mode] = themes[mode] || createThemeMode(mode === "dark"));
};

const ScrollToTop = memo(function () {
  // const { pathname } = useLocation();
  // let location = useLocation();
  // console.log("location", location);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return null;
});

function App({ children }) {
  const { t } = useTranslation();
  const { deactivate } = useWeb3React();

  const { connectWallet, web3, address, networkId, connected } =
    useConnectWallet();
  const { disconnectWallet } = useDisconnectWallet();
  const { isNightMode, setNightMode } = useNightMode();
  const { showModal } = useModal();

  const theme = useMemo(
    () => getTheme(isNightMode ? "dark" : "light"),
    [isNightMode]
  );

  const useStyles = useMemo(() => {
    return makeStyles(appStyle, { defaultTheme: theme });
  }, [theme]);
  const classes = useStyles();

  /* useEffect(() => {
    setModal(createWeb3Modal(t));
    console.log("setModal");
  }, []); */

  useEffect(() => {
    if (showModal || window.ethereum) {
      connectWallet(showModal);
    }
  }, [showModal, connectWallet]);

  const connectWalletCallback = useCallback(() => {
    connectWallet(showModal);
  }, [showModal, connectWallet]);

  const disconnectWalletCallback = useCallback(() => {
    // disconnectWallet(web3, web3Modal);
    try {
      deactivate();
      disconnectWallet(web3, null);
    } catch (ex) {
      console.log(ex);
    }
  }, [deactivate]);

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider>
        <ScrollToTop />
        <div className={classes.page}>
          <Header
            links={
              <HeaderLinks
                address={address}
                connected={connected}
                connectWallet={connectWalletCallback}
                disconnectWallet={disconnectWalletCallback}
                isNightMode={isNightMode}
                setNightMode={() => setNightMode(!isNightMode)}
              />
            }
            isNightMode={isNightMode}
            setNightMode={() => setNightMode(!isNightMode)}
          />
          <div className={classes.container}>
            <div className={classes.children}>
              <NetworkConnectNotice
                web3={web3}
                address={address}
                connectWallet={connectWalletCallback}
                disconnectWallet={disconnectWalletCallback}
                networkId={networkId}
              />
              {networkId === window.REACT_APP_NETWORK_ID ? children : null}
              {/* <Notifier /> */}
            </div>
          </div>
        </div>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
